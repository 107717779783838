import React, { useEffect, useState } from 'react';
import BasicModal from '../components/BasicModal';
import MarketingFooter from '../components/MarketingFooter';
import Navbar from '../components/NavBar';
import truck from '../images/truck1.png';
import offset from '../images/solar.png';
import tracking from '../images/home/track.png';
import { Link } from 'gatsby';
import MarketingHead from '../components/marketing-meta';

const TrackingPage = () => {
    const [salesModal, setSalesModal] = useState(false);


    // load the HS script
    useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
  
      })
  
    }, []);
  
    // populate a lil form
    useEffect(() => {
      if(window.hbspt && salesModal) {
        window.hbspt.forms.create({
          portalId: '14557468',
          formId: '2be2ed45-2b95-4eb4-9301-40856c8cb10d',
          target: '#hubspotForm'
        })
      }
    }, [salesModal])

    return (
        <>
            <title>Kodama - Tracking your emissions</title>
            <MarketingHead />
            <BasicModal title="Join the tracking beta!" isOpen={salesModal} close={() => setSalesModal(false)}>
                Interested in joining the carbon tracking beta?
                <div id="hubspotForm" className="mt-2"/>
            </BasicModal>
            <div className={'bg-hero-image bg-cover p-1 text-white'}>
                <Navbar /> 
                <div className="container xl:px-40 mt-10 mb-8 mx-auto">
                    <div className="min-h-96 p-2 w-full my-12">
                        <p className="text-5xl mb-8 font-bold">Track emissions, add value</p>
                        {/* <div className="px-4 mt-6 mx-2 border-l w-2/3 border-white"> */}
                        <p className="mt-2 text-lg">Learn about your business's impact, and demonstrate value to your customers by tracking emissions in real time.</p>
                        <p className="mt-2 text-lg">Just like you track how much you spend on your business, and make use of accounting software, Kodama provides the accounting solution for your carbon footprint.</p>
                        <p className="mt-2 text-lg">Understand exactly how much carbon you're emitting for each product you sell.</p>
                        {/* </div> */}
                        <button onClick={() => setSalesModal(true)} className="border border-white rounded-md text-white px-4 py-2 mt-4">
                        Join the tracking beta
                        </button>
                    </div>          
                </div> 
            </div>
            <div className="container xl:px-40 my-24 mb-5 mx-auto grid grid-cols-1 lg:grid-cols-2 items-center gap-6 px-4 lg:px-0">
                <div>
                    <img className="px-12" src={tracking} />
                </div>
                <div>
                    <h2 className="text-5xl font-bold text-primary">Keep tabs on manufacturing and transport emissions</h2>
                    <p className="text-gray-700 mt-4">
                        Kodama's tracking tools allow you to track emissions, and quickly quote the cost to offset the carbon impact. 
                        Our service provides an easy way to demonstrate the carbon impact to your customers, and pass the offset on to them.
                    </p>
                </div>
            </div>
            <div className="container xl:px-40 mt-36 mb-5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 items-center px-4 lg:px-0">
                <div>
                    <h1 className="text-5xl font-bold text-primary">Correlate your emissions to offsets</h1>
                </div>
                <div className="border-l border-primary pl-4">
                    <p className="text-lg mt-2 text-gray-700">By using Kodama to log your emissions, you can track and learn about your impact, and correlate any purchased carbon credits to a specific event.</p>
                </div>
            </div>
            
            <div className="container xl:px-40 mt-32 mb-5 px-4 lg:px-0 lg:mx-auto">
                <h2 className="text-center text-3xl font-bold text-secondary-dark">Cover your carbon footprints</h2>
                <p className="text-center text-lg">Turning your emissions into offsets has never been easier</p>
                <div className="w-48 mx-auto border-b border-primary mt-10"></div>
                <div className="grid grid-cols-1 lg:grid-cols-7 px-8 gap-2">
                    <div />
                    <div className="p-1 col-span-2">
                        <img src={truck} alt="Semi Truck" />
                        {/* Designed by macrovector / Freepik */}
                        <div className="text-center">
                            <h3 className="text-xl">GHG Emission</h3>
                            <span>Track your greenhouse emissions as they occur. Add value for your customers by showing the emissions. 
                                {/* <Link to="/tracking" className="text-primary underline">Learn more about our tracking solution.</Link> */}
                            </span>
                        </div>
                    </div>
                    <div className=""/>
                    <div className="p-1 col-span-2">
                        <img src={offset} alt="Green Solar Energy" />
                        {/* Designed by macrovector / Freepik */}
                        <div className="text-center">
                            <h3 className="text-xl">Voluntary Credits</h3>
                            Buy offsets in projects that sequester carbon, and correlate the sequestered carbon to existing emissions. Self-emitted or otherwise.
                        </div>
                    </div>
                    <div />
                </div>
            </div>
      <MarketingFooter />
      </>
    )
}

export default TrackingPage;
